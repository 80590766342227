import { useCallback, useMemo } from 'react';
import {
  useApolloClient,
  useMutation,
  useQuery,
  ApolloClient,
  DocumentNode,
  MutationHookOptions,
} from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useEventBus, useSettings } from '@lwe/toolkit/utils';
import { USER_UNAUTHENTICATED, USER_AUTHENTICATED, USER_REGISTERED } from './events';
import { setTimeout, clearTimeout } from 'worker-timers';
import createPersistedState from 'use-persisted-state';

import {
  VIEWER_QUERY,
  IMPERSONATOR_QUERY,
  AUTHENTICATE_WITH_FACEBOOK_MUTATION,
  NEW_USER_QUERY,
  REDEEM_USER_INVITE_MUTATION,
  RESET_PASSWORD_MUTATION,
  RESET_PASSWORD_REQUEST_MUTATION,
  AUTHENTICATE_WITH_EMAIL_MUTATION,
  AUTHENTICATE_WITH_MAGIC_TOKEN_MUTATION,
  REGISTRATION_MUTATION,
  GENERATE_MULTIPASS_URL_MUTATION,
} from './gql';

import {
  AuthenticateWithMagicToken,
  AuthenticateWithMagicTokenVariables,
} from './generatedTypes/AuthenticateWithMagicToken';
import {
  AuthenticateWithFacebook,
  AuthenticateWithFacebookVariables,
} from './generatedTypes/AuthenticateWithFacebook';
import {
  AuthSignInWithEmail,
  AuthSignInWithEmailVariables,
} from './generatedTypes/AuthSignInWithEmail';
import { Register, RegisterVariables } from './generatedTypes/Register';
import { ResetPassword, ResetPasswordVariables } from './generatedTypes/ResetPassword';
import { RedeemUserInvite, RedeemUserInviteVariables } from './generatedTypes/RedeemUserInvite';
import { CurrentUserQuery } from './generatedTypes/CurrentUserQuery';
import { NewUserQuery } from './generatedTypes/NewUserQuery';
import {
  AuthImpersonatorQuery,
  AuthImpersonatorQueryVariables,
} from './generatedTypes/AuthImpersonatorQuery';
import { GenerateMultipassUrl, GenerateMultipassUrlVariables } from './generatedTypes/GenerateMultipassUrl';
import { session } from './generatedTypes/session';

const AUTH_TOKEN = 'authToken';

export const useImpersonatorToken = createPersistedState<string | null>('impersonatorToken');
export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN);
export const setAuthToken = (token: string) => localStorage.setItem(AUTH_TOKEN, token);
const removeAuthToken = () => localStorage.removeItem(AUTH_TOKEN);

const useAuthMutation = <TData, TVariables>(
  mutationName: string,
  query: DocumentNode,
  options: MutationHookOptions<TData, TVariables> = {},
) => {
  const [mutation, { client }] = useMutation<TData, TVariables>(query, options);
  const eventBus = useEventBus();
  return useCallback(
    async (params) => {
      try {
        const response = await mutation({
          ...params,
          update: (cache, { data }) => {
            const { user, token } = data?.[mutationName];
            setAuthToken(token);
            cache.writeQuery({
              query: VIEWER_QUERY,
              data: {
                viewer: user,
              },
            });
          },
        });
        monitorAuthToken(client);
        client.reFetchObservableQueries();
        eventBus.publish(USER_AUTHENTICATED, {
          user: response?.data?.[mutationName]?.user,
        });
        return response;
      } catch (e) {
        console.error(`Error with useAuthMutation ${mutationName}`, e);
        throw e;
      }
    },
    [mutationName, mutation, client],
  );
};

export const useAuthenticateWithEmail = () => {
  const authenticate = useAuthMutation<AuthSignInWithEmail, AuthSignInWithEmailVariables>(
    'authenticateWithEmail',
    AUTHENTICATE_WITH_EMAIL_MUTATION,
  );
  return useCallback(
    (email: string, password: string, returnTo: string | null, multipass: boolean) => {
      return authenticate({
        variables: {
          email,
          password,
          returnTo,
          multipass,
        },
      });
    },
    [authenticate],
  );
};

export const useAuthenticateWithFacebook = () => {
  const authenticate = useAuthMutation<AuthenticateWithFacebook, AuthenticateWithFacebookVariables>(
    'authenticateWithFacebook',
    AUTHENTICATE_WITH_FACEBOOK_MUTATION,
  );
  return useCallback(
    (signedRequest, signupType, school, returnTo, multipass) => {
      return authenticate({
        variables: {
          signedRequest,
          signupType,
          school,
          returnTo,
          multipass,
        },
      });
    },
    [authenticate],
  );
};

export const useAuthenticateWithMagicToken = () => {
  const authenticate = useAuthMutation<
    AuthenticateWithMagicToken,
    AuthenticateWithMagicTokenVariables
  >('authenticateWithMagicToken', AUTHENTICATE_WITH_MAGIC_TOKEN_MUTATION);
  return useCallback(
    (token) => {
      return authenticate({
        variables: {
          token,
        },
      });
    },
    [authenticate],
  );
};

export const useRegistration = () => {
  const eventBus = useEventBus();
  const { data } = useQuery<NewUserQuery>(NEW_USER_QUERY);
  const newUser = data?.user;
  const mutation = useAuthMutation<Register, RegisterVariables>('register', REGISTRATION_MUTATION);
  const register = useCallback(
    async (variables) => {
      const response = await mutation({ variables });
      if (response?.data?.register?.user) {
        eventBus.publish(USER_REGISTERED, response?.data.register.user);
        return response;
      }
      return null;
    },
    [mutation, eventBus],
  );
  return useMemo(() => ({ register, newUser }), [register, newUser]);
};

export const useRedeemUserInvite = () => {
  const redeemUserInvite = useAuthMutation<RedeemUserInvite, RedeemUserInviteVariables>(
    'redeemUserInvite',
    REDEEM_USER_INVITE_MUTATION,
  );
  return useCallback(
    (token, password, profile) => {
      return redeemUserInvite({
        variables: {
          token,
          password,
          profile,
        },
      });
    },
    [redeemUserInvite],
  );
};

export const useResetPassword = () => {
  const resetPassword = useAuthMutation<ResetPassword, ResetPasswordVariables>(
    'resetPassword',
    RESET_PASSWORD_MUTATION,
  );
  return useCallback(
    (resetToken, newPassword, returnTo, multipass) => {
      return resetPassword({
        variables: {
          resetToken,
          newPassword,
          returnTo,
          multipass
        },
      });
    },
    [resetPassword],
  );
};

export const useResetPasswordRequest = () => {
  const [resetPasswordRequest] = useMutation(RESET_PASSWORD_REQUEST_MUTATION);
  return useCallback(
    (email, returnTo) => {
      return resetPasswordRequest({
        variables: {
          email,
          returnTo
        },
      });
    },
    [resetPasswordRequest],
  );
};

export const useRevertImpersonator = () => {
  const [impersonatorToken, setImpersonatorToken] = useImpersonatorToken(null);
  const client = useApolloClient();
  const settings = useSettings();
  const homepageRedirect = settings?.homepageRedirect;
  const history = useHistory();
  return useCallback(() => {
    if (impersonatorToken) setAuthToken(impersonatorToken);
    setImpersonatorToken(null);
    client.resetStore();
    history.push(homepageRedirect || '/classrooms');
  }, [impersonatorToken, client, history]);
};

export const useLogout = () => {
  const [_, setImpersonatorToken] = useImpersonatorToken(null);
  const client = useApolloClient();
  const eventBus = useEventBus();

  return useCallback(async () => {
    try {
      removeAuthToken();
      monitorAuthToken(client); // clear session timer
      setImpersonatorToken(null);

      eventBus.publish(USER_UNAUTHENTICATED);

      client.reFetchObservableQueries();
    } catch (e) {
      console.error('Error in useLogout', e);
    }
  }, [client]);
};

export const useLogoutAndRedirect = () => {
  const history = useHistory();
  const logout = useLogout();
  const settings = useSettings();
  const homepageRedirect = settings?.homepageRedirect;

  return useCallback(() => {
    logout();
    if (homepageRedirect) {
      history.push(homepageRedirect);
    }
  }, [logout, history, homepageRedirect]);
};

export const useImpersonator = () => {
  const [impersonatorToken] = useImpersonatorToken(null);
  const variables = {
    impersonating: !!impersonatorToken,
    impersonatorToken,
  };
  const { data } = useQuery<AuthImpersonatorQuery, AuthImpersonatorQueryVariables>(
    IMPERSONATOR_QUERY,
    { variables },
  );
  return useMemo(() => (data ? data.impersonator : null), [data]);
};

export const useGenerateMultipassUrl = () => {
  const [generateMultipassUrl] = useMutation<GenerateMultipassUrl, GenerateMultipassUrlVariables>(GENERATE_MULTIPASS_URL_MUTATION);
  return useCallback(
    (returnTo) => {
      return generateMultipassUrl({
        variables: {
          returnTo
        },
      });
    },
    [generateMultipassUrl],
  );
};


type TimerType = number | null;

let authTimer: TimerType = null;

export const monitorAuthToken = (client: ApolloClient<object>) => {
  const token = getAuthToken();

  if (authTimer) clearTimeout(authTimer);
  if (!token) return;
  const { exp } = parseJwt(token);
  const now = new Date().getTime();
  const expires = (exp - 60) * 1000 - now;
  // console.log(`Setting session to expire in ${expires/(1000*60)} minutes`);
  authTimer = setTimeout(() => {
    removeAuthToken();
    localStorage.removeItem('basketToken');
    client.writeQuery({
      query: VIEWER_QUERY,
      data: { viewer: null },
    });
    authTimer = null;
  }, expires);
};

export const useCurrentUser = () => {
  const [impersonatorToken] = useImpersonatorToken(null);
  const variables = {
    impersonating: !!impersonatorToken,
    impersonatorToken,
  };

  const { data, loading } = useQuery<CurrentUserQuery>(VIEWER_QUERY, { ssr: false, variables });
  return useMemo(() => {
    return {
      currentUser: data ? data.viewer : null,
      impersonator: data ? data.impersonator : null,
      loading,
    };
  }, [data, loading]);
};

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};
